/** @jsx jsx */
import { jsx } from "@emotion/core"

import Skill from "../components/skill"
import Card, { CardTitle } from "../components/card"
import Layout from "../components/layout"

const data = [
  {
    title: "Engineering",
    skills: [
      { label: "Functional Programming", score: 4 },
      { label: "Object-Oriented Programming", score: 3 },
      { label: "Test-Driven Development", score: 3.5 },
      { label: "Domain-Driven Design", score: 2 },
    ],
  },
  {
    title: "Frontend",
    skills: [
      { label: "Javascript", score: 4.5 },
      { label: "Typescript", score: 4.5 },
      { label: "React", score: 4.5 },
      { label: "React Hooks", score: 4 },
      { label: "Redux", score: 4.5 },
      { label: "Apollo Client", score: 4 },
      { label: "Ramda", score: 4.5 },
      { label: "HTML5", score: 4 },
      { label: "CSS", score: 4.5 },
      { label: "Emotion (CSS-in-JS)", score: 3.5 },
      { label: "Responsive Web Design", score: 4 },
      { label: "HTML Email Development", score: 3.5 },
    ],
  },
  {
    title: "Backend",
    skills: [
      { label: "NodeJS", score: 2.5 },
      { label: "Golang", score: 2 },
      { label: "Apollo Server", score: 2 },
      { label: "RESTful API Development", score: 3 },
      { label: "GraphQL API Development", score: 4 },
    ],
  },
  {
    title: "DBMS",
    skills: [
      { label: "MySQL", score: 3 },
      { label: "PostgreSQL", score: 3 },
      { label: "MongoDB", score: 3 },
      { label: "DynamoDB", score: 1.5 },
    ],
  },
  {
    title: "Other",
    skills: [
      { label: "Git", score: 4 },
      { label: "Github", score: 3 },
      { label: "Docker", score: 2 },
      { label: "Webpack", score: 2.5 },
      { label: "Command-Line", score: 2.5 },
    ],
  },
]

export default function Skills() {
  return (
    <Layout>
      {data.map((section, idx) => (
        <Card key={idx}>
          <CardTitle>{section.title}</CardTitle>
          {section.skills.map((skill, idx) => (
            <Skill key={idx} {...skill} />
          ))}
        </Card>
      ))}
    </Layout>
  )
}
