/** @jsx jsx */
import { jsx } from "@emotion/core"

export default function Skill({ label, score }) {
  return (
    <div
      css={{
        marginTop: 16,
        display: "flex",
      }}
    >
      <div css={{ flex: 1, border: `1px #b2dfdb solid`, borderRadius: 3 }}>
        <div
          css={{
            lineHeight: 1.35,
            height: "100%",
            padding: 4,
            paddingRight: 8,
            paddingLeft: 8,
            fontSize: 16,
            display: "flex",
            color: "#333333",
            background: "#e0f2f1",
            borderRight: `1px #b2dfdb solid`,
            width: `${(score / 5) * 100}%`,
            justifyContent: "space-between",
          }}
        >
          <span>{label}</span>{" "}
          <span
            css={{ fontSize: "0.85em", marginTop: 2, display: "inline-block" }}
          >{`${(score / 5) * 100}%`}</span>
        </div>
      </div>
    </div>
  )
}
